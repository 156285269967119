// src/components/AdminInterface.tsx
import React, { useState, useEffect } from "react";
import { useAuth, useAdmin } from "../hooks";
import { ErrorBoundary } from ".";
import { ADMIN_UID } from "../config/constants";

function AdminDashboard() {
  const [bannerMessage, setBannerMessage] = useState("");
  const { loading, error, stats, updateBanner, fetchStats } = useAdmin();

  useEffect(() => {
    fetchStats();
    // Refresh stats every 5 minutes
    const interval = setInterval(fetchStats, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleBannerUpdate = async () => {
    if (!bannerMessage.trim()) return;

    try {
      await updateBanner(bannerMessage);
      setBannerMessage(""); // Clear input on success
    } catch (error) {
      console.error("Failed to update banner:", error);
    }
  };

  if (loading && !stats) {
    return (
      <div className="bg-white sm:w-full mx-auto p-4">
        <h3 className="text-2xl font-bold mb-4">Start Admin Interface</h3>
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/2 mb-4"></div>
          <div className="h-32 bg-gray-200 rounded mb-4"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white sm:w-full mx-auto p-4">
        <h3 className="text-2xl font-bold mb-4">Start Admin Interface</h3>
        <div className="text-red-500">Error: {error.message}</div>
      </div>
    );
  }

  return (
    <div className="bg-white sm:w-full mx-auto p-4">
      <h3 className="text-2xl font-bold mb-4">Start Admin Interface</h3>

      {/* Banner Management */}
      <div className="mb-8">
        <h4 className="text-xl font-semibold mb-2">Banner Management</h4>
        <div className="flex flex-col space-y-2">
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            value={bannerMessage}
            onChange={(e) => setBannerMessage(e.target.value)}
            placeholder="Enter banner message..."
          />
          <button
            className="w-auto py-2 bg-blue-500 text-white rounded-md px-4 hover:bg-blue-600"
            onClick={handleBannerUpdate}
            disabled={loading}>
            {loading ? "Updating..." : "Update Banner"}
          </button>
        </div>
      </div>

      {/* Stats Display */}
      {stats && (
        <div className="mt-6">
          <h4 className="text-xl font-semibold mb-2">Statistics</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-600">Total Users</p>
              <p className="text-2xl font-bold">{stats.totalUsers}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-600">Total URLs</p>
              <p className="text-2xl font-bold">{stats.totalUrls}</p>
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Last updated: {stats.lastUpdated.toDate().toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
}

function AdminInterface() {
  const { user } = useAuth();

  if (user?.uid !== ADMIN_UID) {
    return null;
  }

  return (
    <ErrorBoundary>
      <AdminDashboard />
    </ErrorBoundary>
  );
}

export default AdminInterface;
