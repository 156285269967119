// src/hooks/useUrls.ts
import { useState, useEffect } from "react";
import { urlService, UrlData } from "../services/firebase";

export function useUrls(userId: string | undefined) {
  const [urls, setUrls] = useState<UrlData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setUrls([]);
      setLoading(false);
      return;
    }

    const unsubscribe = urlService.subscribeToUrls(userId, (newUrls) => {
      setUrls(newUrls);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [userId]);

  const addUrl = async (urlData: Omit<UrlData, "id">) => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      return await urlService.addUrl(userId, urlData);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to add URL"));
      throw err;
    }
  };

  const updateUrl = async (urlId: string, urlData: Partial<UrlData>) => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      await urlService.updateUrl(userId, urlId, urlData);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to update URL"));
      throw err;
    }
  };

  const deleteUrl = async (urlId: string) => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      await urlService.deleteUrl(userId, urlId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to delete URL"));
      throw err;
    }
  };

  const updateUrlOrder = async (urlId: string, newOrder: number) => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      await urlService.updateUrlOrder(userId, urlId, newOrder);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to update URL order")
      );
      throw err;
    }
  };

  return {
    urls,
    loading,
    error,
    addUrl,
    updateUrl,
    deleteUrl,
    updateUrlOrder,
  };
}
