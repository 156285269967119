// src/services/crypto/index.ts
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

interface CryptoConfig {
  secretKey: string;
}

export const cryptoService = {
  config: {
    secretKey:
      "e3b2c4d570beb99bc5b7dc829b40c7c2405a3ce2f74b2403ac49574a121602f5",
  } as CryptoConfig,

  encrypt: (data: string): string => {
    try {
      return AES.encrypt(data, cryptoService.config.secretKey).toString();
    } catch (error) {
      console.error("Encryption failed:", error);
      throw new Error("Failed to encrypt data");
    }
  },

  decrypt: (encryptedData: string): string => {
    try {
      const bytes = AES.decrypt(encryptedData, cryptoService.config.secretKey);
      const decryptedData = bytes.toString(Utf8);
      if (!decryptedData) {
        throw new Error("Decryption resulted in empty data");
      }
      return decryptedData;
    } catch (error) {
      console.error("Decryption failed:", error);
      throw new Error("Failed to decrypt data");
    }
  },
};
