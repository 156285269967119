// src/components/Scratchpad.tsx
import React from "react";
import { useAuth, useScratchpad } from "../hooks";

function Scratchpad() {
  const { user } = useAuth();
  const { text, loading, error, updateText, clearText } = useScratchpad(
    user?.uid
  );

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateText(event.target.value);
  };

  if (!user) return null;

  if (loading) {
    return (
      <div className="my-4">
        <h2 className="text-2xl font-bold mb-2">Scratchpad</h2>
        <div className="animate-pulse">
          <div className="h-44 bg-gray-200 rounded-md"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-4">
        <h2 className="text-2xl font-bold mb-2">Scratchpad</h2>
        <div className="text-red-500">Error: {error.message}</div>
      </div>
    );
  }

  return (
    <div className="my-4">
      <h2 className="text-2xl font-bold mb-2">Scratchpad</h2>
      <textarea
        className="w-full h-44 p-2 border-2 border-gray-300 rounded-md"
        value={text}
        onChange={handleTextChange}
        placeholder="Type here to save a quick note to self."
      />
      <button className="text-sm text-red-600 mb-2" onClick={() => clearText()}>
        Clear Scratchpad
      </button>
    </div>
  );
}

export default Scratchpad;
