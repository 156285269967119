// src/components/UrlManager.tsx
import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useAuth, useUrls } from "../hooks";
import fallbackIcon from "../assets/images/fallback-icon.png";

function UrlManager() {
  const { user } = useAuth();
  const { urls, loading, error, addUrl, deleteUrl, updateUrlOrder } = useUrls(
    user?.uid
  );
  const [newUrl, setNewUrl] = useState("");
  const [newUrlName, setNewUrlName] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [newlyAdded, setNewlyAdded] = useState<string | null>(null);
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const handleAddUrl = async () => {
    if (urls.length >= 50) {
      alert("Maximum limit of 50 URLs reached");
      return;
    }

    let formattedUrl = newUrl;
    if (!/^https?:\/\//i.test(newUrl)) {
      formattedUrl = "https://" + newUrl;
    }

    try {
      const urlObject = new URL(formattedUrl);
      const domainName = urlObject.hostname;

      if (urls.some((urlData) => urlData.url === formattedUrl)) {
        alert("You have already added this URL.");
        return;
      }

      const id = await addUrl({
        url: formattedUrl,
        name: newUrlName || domainName,
        order: urls.length,
      });

      setNewUrl("");
      setNewUrlName("");
      setShowAddForm(false);
      setNewlyAdded(id);
      setTimeout(() => setNewlyAdded(null), 4000);
    } catch (error) {
      alert("Please enter a valid URL");
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(urls);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update all affected URLs with new order
    const updates = items.map((item, index) => updateUrlOrder(item.id, index));

    await Promise.all(updates);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="my-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="urls" direction="horizontal">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="grid sm:grid-cols-4 gap-2 sm:gap-4 mb-6">
                {urls.map((urlData, index) => (
                  <Draggable
                    key={urlData.id}
                    draggableId={urlData.id}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`relative bg-blue-100 rounded-md p-2 hover:bg-blue-300 shadow-sm truncate ${
                          urlData.id === newlyAdded ? "animate-pulse" : ""
                        }`}
                        onMouseEnter={() => setHoveredId(urlData.id)}
                        onMouseLeave={() => setHoveredId(null)}>
                        <a
                          href={urlData.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-ellipsis overflow-hidden">
                          <img
                            src={`${new URL(urlData.url).origin}/favicon.ico`}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = fallbackIcon;
                              target.onerror = null;
                            }}
                            alt=""
                            width="16"
                            height="16"
                            className="inline mr-2"
                          />
                          {urlData.name || new URL(urlData.url).hostname}
                        </a>
                        <button
                          onClick={() => deleteUrl(urlData.id)}
                          className={`${
                            hoveredId === urlData.id ? "inline-block" : "hidden"
                          } sm:hover:inline-block text-gray-800 absolute right-2 top-3`}>
                          <XCircleIcon className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <button onClick={() => setShowAddForm(!showAddForm)} className="mb-4">
          {showAddForm ? (
            <span className="cancel-text">x</span>
          ) : (
            <span className="bg-pink-200 p-2 rounded-lg w-auto">
              Add a new link
            </span>
          )}
        </button>

        {showAddForm && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddUrl();
            }}
            className="justify-center bg-gray-200 p-4 rounded-md mb-4">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Link (ie: etrade.com)
              </label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                type="text"
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
                placeholder="URL/Link"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Link name (ie: My Stocks)
              </label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                type="text"
                value={newUrlName}
                onChange={(e) => setNewUrlName(e.target.value)}
                placeholder="Link name (optional)"
              />
            </div>
            <button
              type="submit"
              className="bg-pink-200 text-black p-2 rounded-lg hover:bg-pink-400 hover:text-white">
              Save link
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default UrlManager;
