// src/components/TomorrowWidget.tsx
import React from "react";
import { SunIcon } from "@heroicons/react/20/solid";
import { useAuth, useWeather } from "../hooks";

const tomorrowStyle: React.CSSProperties = {
  paddingBottom: "22px",
  position: "relative",
};

interface TomorrowWidgetProps {
  setWidgetRegistered: (value: boolean) => void;
}

function TomorrowWidget({ setWidgetRegistered }: TomorrowWidgetProps) {
  const { user } = useAuth();
  const { enabled, loading, error, initializeWidget, removeWidget } =
    useWeather(user?.uid);

  const handleAddWeatherWidget = async () => {
    try {
      await initializeWidget();
      setWidgetRegistered(true);
    } catch (error) {
      console.error("Failed to add weather widget:", error);
    }
  };

  const handleRemoveWeatherWidget = async () => {
    try {
      await removeWidget();
      setWidgetRegistered(false);
    } catch (error) {
      console.error("Failed to remove weather widget:", error);
    }
  };

  if (!user) return null;

  if (loading) {
    return (
      <div style={tomorrowStyle} className="animate-pulse">
        <div className="h-20 bg-gray-200 rounded-md"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={tomorrowStyle} className="text-red-500">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div style={tomorrowStyle}>
      {enabled && (
        <p
          className="text-sm text-gray-500 relative hover:text-red-500 text-right mb-2 mr-2 cursor-pointer"
          onClick={handleRemoveWeatherWidget}>
          Remove
        </p>
      )}
      {enabled ? (
        <div
          className="tomorrow"
          data-language="EN"
          data-unit-system="IMPERIAL"
          data-skin="light"
          data-widget-type="upcoming"
        />
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded absolute bottom-0 right-0 mb-2 mr-2"
          onClick={handleAddWeatherWidget}>
          <SunIcon className="h-4 w-4 inline-block mr-1" />
          Add Weather Widget
        </button>
      )}
    </div>
  );
}

export default TomorrowWidget;
