// src/hooks/useBanner.ts
import { useState, useEffect } from "react";
import { bannerService, BannerMessage } from "../services/banner";

export function useBanner() {
  const [bannerMessage, setBannerMessage] = useState<BannerMessage | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = bannerService.subscribeToBanner((message) => {
      setBannerMessage(message);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateBanner = async (message: string) => {
    try {
      setError(null);
      await bannerService.updateBanner(message);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to update banner")
      );
      throw err;
    }
  };

  const dismissBanner = async () => {
    try {
      setError(null);
      await bannerService.dismissBanner();
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to dismiss banner")
      );
      throw err;
    }
  };

  return {
    bannerMessage,
    loading,
    error,
    updateBanner,
    dismissBanner,
  };
}
