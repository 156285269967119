// src/services/banner/index.ts
import {
  doc,
  onSnapshot,
  deleteDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";

export interface BannerMessage {
  message: string;
  timestamp: Timestamp;
}

export const bannerService = {
  subscribeToBanner: (callback: (message: BannerMessage | null) => void) => {
    const bannerDoc = doc(db, "banner", "current");

    return onSnapshot(bannerDoc, (doc) => {
      if (doc.exists()) {
        callback(doc.data() as BannerMessage);
      } else {
        callback(null);
      }
    });
  },

  updateBanner: async (message: string) => {
    const bannerDoc = doc(db, "banner", "current");
    await setDoc(bannerDoc, {
      message,
      timestamp: Timestamp.now(),
    });
  },

  dismissBanner: async () => {
    const bannerDoc = doc(db, "banner", "current");
    await deleteDoc(bannerDoc);
  },
};
