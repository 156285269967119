// src/hooks/useWeather.ts
import { useState, useEffect } from "react";
import { weatherService } from "../services/weather";
import { weatherWidgetService } from "../services/firebase";

export function useWeather(userId: string | undefined) {
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setEnabled(false);
      setLoading(false);
      return;
    }

    const loadWidgetStatus = async () => {
      try {
        const status = await weatherWidgetService.getWidgetStatus(userId);
        setEnabled(status?.enabled ?? false);
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error("Failed to load weather widget status")
        );
      } finally {
        setLoading(false);
      }
    };

    loadWidgetStatus();
  }, [userId]);

  const initializeWidget = async () => {
    try {
      setError(null);
      if (!userId) throw new Error("User not authenticated");

      const location = await weatherService.getCurrentLocation();
      await weatherService.loadScript();
      weatherService.initializeWidget(
        `${location.latitude},${location.longitude}`
      );
      await weatherWidgetService.updateWidgetStatus(userId, true);
      setEnabled(true);
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error("Failed to initialize weather widget")
      );
      throw err;
    }
  };

  const removeWidget = async () => {
    try {
      setError(null);
      if (!userId) throw new Error("User not authenticated");
      await weatherWidgetService.updateWidgetStatus(userId, false);
      setEnabled(false);
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error("Failed to remove weather widget")
      );
      throw err;
    }
  };

  return {
    enabled,
    loading,
    error,
    initializeWidget,
    removeWidget,
  };
}
