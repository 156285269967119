// src/config/constants.ts
export const ADMIN_UID = "XR8JNuzXUqXs3kkqntXaRKQQQUs2";
export const PEXELS_API_KEY =
  "563492ad6f917000010000014060d806c66c47b88b9b4d7f8c487692";
export const WEATHER_API_KEY = "bDSGBaZ5H5P5J3JEAkaSaPf0WX18hXwv";
export const CRYPTO_SECRET_KEY =
  "e3b2c4d570beb99bc5b7dc829b40c7c2405a3ce2f74b2403ac49574a121602f5";

export const MAX_URLS = 50;
export const WEATHER_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
