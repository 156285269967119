// src/App.tsx
import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { useAuth } from "./hooks";
import {
  Auth,
  UrlManager,
  SearchWidget,
  TomorrowWidget,
  Scratchpad,
  Banner,
  AdminInterface,
} from "./components";
import "./styles/App.css";

Sentry.init({
  dsn: "https://d6103b0044a542b385428dca38a7a08d@o543459.ingest.sentry.io/4505427676954624",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  tracesSampleRate: 1.0,
});

const App: React.FC = () => {
  const { user, loading } = useAuth();
  const [widgetRegistered, setWidgetRegistered] = useState<boolean>(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white">
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
          aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <h1 className="block text-3xl font-logo font-bold tracking-tight">
                start
              </h1>
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <Auth />
          </div>
        </nav>
      </header>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {user && (
          <div className="">
            <SearchWidget />
            <UrlManager />
            <TomorrowWidget setWidgetRegistered={setWidgetRegistered} />
            <Scratchpad />
            <div className="w-18">
              <Banner />
            </div>
            <AdminInterface />
          </div>
        )}

        {!user && (
          <div className="max-w-7xl mx-auto">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-8 px-4 sm:pt-8 sm:px-6 lg:px-8">
                <div className="text-left">
                  <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    A helpful place to start your day
                  </p>
                  <p className="max-w-xl mt-5 text-left text-xl text-gray-500">
                    Add sites and apps you visit frequently and access them
                    easily from any device.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Footer component here... */}
    </div>
  );
};

export default App;
