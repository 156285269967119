// src/components/Banner.tsx
import React from "react";
import { formatDistanceToNow } from "date-fns";
import Linkify from "react-linkify";
import { useBanner } from "../hooks";

function Banner() {
  const { bannerMessage, loading, error, dismissBanner } = useBanner();

  if (loading) return null;
  if (error) return null;
  if (!bannerMessage) return null;

  return (
    <div className="banner bg-yellow-100 p-4 text-left rounded-md shadow-sm visited:text-purple-600">
      <Linkify>{bannerMessage.message}</Linkify>
      <div className="mt-4 text-sm text-gray-800">
        {bannerMessage.timestamp &&
          `${formatDistanceToNow(bannerMessage.timestamp.toDate())} ago`}
      </div>
      <button className="text-sm text-red-600 mt-2" onClick={dismissBanner}>
        Dismiss
      </button>
    </div>
  );
}

export default Banner;
