// src/services/weather/index.ts
interface WeatherConfig {
  apiKey: string;
  language: string;
  unitSystem: string;
}

export const weatherService = {
  config: {
    apiKey: "bDSGBaZ5H5P5J3JEAkaSaPf0WX18hXwv",
    language: "EN",
    unitSystem: "IMPERIAL",
  } as WeatherConfig,

  initializeWidget: (locationId: string) => {
    if (window.__TOMORROW__?.init) {
      window.__TOMORROW__.init({
        locationId,
        language: weatherService.config.language,
        unitSystem: weatherService.config.unitSystem,
        apiKey: weatherService.config.apiKey,
        skin: "light",
        widgetType: "upcoming",
      });
    }
  },

  loadScript: (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (document.getElementById("tomorrow-sdk")) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = "tomorrow-sdk";
      script.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";
      script.async = true;

      script.onload = () => resolve();
      script.onerror = (error) => reject(error);

      document.body.appendChild(script);
    });
  },

  getCurrentLocation: (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => reject(error)
      );
    });
  },
};
