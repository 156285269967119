// src/services/admin/index.ts
import {
  doc,
  setDoc,
  collection,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";

export interface AdminStats {
  totalUsers: number;
  totalUrls: number;
  lastUpdated: Timestamp;
}

export const adminService = {
  updateBanner: async (message: string) => {
    const bannerDoc = doc(db, "banner", "current");
    await setDoc(bannerDoc, {
      message,
      timestamp: Timestamp.now(),
    });
  },

  getStats: async (): Promise<AdminStats> => {
    // Get total users
    const usersSnapshot = await getDocs(collection(db, "users"));
    const totalUsers = usersSnapshot.size;

    // Get total URLs across all users
    let totalUrls = 0;
    for (const userDoc of usersSnapshot.docs) {
      const urlsSnapshot = await getDocs(
        collection(db, `users/${userDoc.id}/urls`)
      );
      totalUrls += urlsSnapshot.size;
    }

    return {
      totalUsers,
      totalUrls,
      lastUpdated: Timestamp.now(),
    };
  },
};
