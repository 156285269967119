// src/hooks/useAdmin.ts
import { useState } from "react";
import { adminService, AdminStats } from "../services/admin";

export function useAdmin() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [stats, setStats] = useState<AdminStats | null>(null);

  const updateBanner = async (message: string) => {
    try {
      setLoading(true);
      setError(null);
      await adminService.updateBanner(message);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to update banner")
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      setError(null);
      const newStats = await adminService.getStats();
      setStats(newStats);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch stats"));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    stats,
    updateBanner,
    fetchStats,
  };
}
