// src/components/SearchWidget.tsx
import React, { useState } from "react";

interface SearchWidgetProps {
  className?: string;
}

function SearchWidget({ className = "" }: SearchWidgetProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;

    // Construct DuckDuckGo search URL
    const searchUrl = `https://duckduckgo.com/?q=${encodeURIComponent(
      searchTerm
    )}`;
    window.open(searchUrl, "_blank", "noopener,noreferrer");
    setSearchTerm("");
  };

  return (
    <div className={`flex items-center justify-center my-4 ${className}`}>
      <form
        className="w-full max-w-lg mb-4 rounded-md"
        onSubmit={handleSubmit}
        method="get"
        action="https://duckduckgo.com/"
        target="_blank"
        rel="noopener noreferrer">
        <div className="flex items-stretch bg-white rounded-lg shadow-md overflow-hidden border-2 border-gray-300">
          <input
            type="text"
            name="q"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search DuckDuckGo"
            className="flex-grow px-4 py-2 text-gray-700 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-blue-200"
            autoFocus
          />
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
            Search
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchWidget;
