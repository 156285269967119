// src/hooks/useScratchpad.ts
import { useState, useEffect } from "react";
import { scratchpadService } from "../services/firebase";
import { cryptoService } from "../services/crypto";

export function useScratchpad(userId: string | undefined) {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setText("");
      setLoading(false);
      return;
    }

    const loadScratchpad = async () => {
      try {
        const data = await scratchpadService.getScratchpad(userId);
        if (data) {
          const decryptedText = cryptoService.decrypt(data.text);
          setText(decryptedText);
        }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to load scratchpad")
        );
      } finally {
        setLoading(false);
      }
    };

    loadScratchpad();
  }, [userId]);

  const updateText = async (newText: string) => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      const encryptedText = cryptoService.encrypt(newText);
      await scratchpadService.updateScratchpad(userId, encryptedText);
      setText(newText);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to update scratchpad")
      );
      throw err;
    }
  };

  const clearText = async () => {
    try {
      if (!userId) throw new Error("User not authenticated");
      setError(null);
      await scratchpadService.clearScratchpad(userId);
      setText("");
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to clear scratchpad")
      );
      throw err;
    }
  };

  return {
    text,
    loading,
    error,
    updateText,
    clearText,
  };
}
